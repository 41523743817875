import React from 'react'
import './App.css'
import { Container } from 'react-bootstrap'
import 'aos/dist/aos.css'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import Resume from "./components/resume";

function App() {
  return (
    <div className="bg-dark-subtle">
      <Header />
      <Container
        className="rounded-2 mt-3 mb-3 shadow-sm bg-light-subtle p-sm-5 h-100"
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="resume" element={<Resume />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  )
}

export default App
