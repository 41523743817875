// FilterButton.tsx
import React from 'react'

type FilterButtonProps = {
  hashtag: string
  isSelected: boolean
  onClick: () => void
};

const FilterButton: React.FC<FilterButtonProps> = ({ hashtag, isSelected, onClick }) => (
  <button
    className={`mt-1 btn btn-outline-primary me-2 ${isSelected ? 'active' : ''}`}
    onClick={onClick}
  >
    {`#${hashtag}`}
  </button>
)

export default FilterButton
