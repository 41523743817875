import { Col, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import styles from '../styles.module.css'
import { JobExperience } from '../resume/type'
import { differenceInMonths, differenceInYears, parse } from 'date-fns';

type TechJourneyProps = {
  jobExperiences: JobExperience[]
}

function calculateDuration(dateRange: string) {
  const currentDate = new Date();
  const [startMonthYear, endMonthYear] = dateRange.split(' - ');

  const startDate = parse(startMonthYear, 'MMM yyyy', new Date());
  const endDate = endMonthYear === 'present' ? currentDate : parse(endMonthYear, 'MMM yyyy', new Date());

  const yearsDiff = differenceInYears(endDate, startDate);
  const monthsDiff = differenceInMonths(endDate, startDate) % 12;

  const formattedYears = yearsDiff > 0 ? `${yearsDiff}y` : '';
  const formattedMonths = monthsDiff > 0 ? `${monthsDiff}m` : '';

  return `${formattedYears} ${formattedMonths}`.trim();
}

const TechJourney: React.FC<TechJourneyProps> = ({ jobExperiences }) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [filteredJobExperiences, setFilteredJobExperiences] = useState<JobExperience[]>([])

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    setIsTransitioning(true)

    const timeoutId = setTimeout(() => {
      setIsTransitioning(false)
      setFilteredJobExperiences(jobExperiences)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [jobExperiences])

  return (
    <Row>
      <Col>
        <h3 className={`${styles['resume-title']}`}>Tech Journey</h3>
        {filteredJobExperiences.map((experience, index) => (
          <div key={experience.id}
               className={`${styles['resume-item']} ${styles['filteredContent']} ${isTransitioning ? styles.hidden : ''}`}>
            <Row>
              <Col className="col-6">
                <h4>{experience.title}</h4>
              </Col>
              <Col className="col-6">
                <h5>{experience.date} ({calculateDuration(experience.date)})</h5>
              </Col>
            </Row>
            <p>
              <em>{experience.company}</em>
            </p>
            <p>{experience.description}</p>
            <ul>
              {experience.highlights.map((highlight, idx) => (
                <li key={idx}>{highlight}</li>
              ))}
            </ul>
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default TechJourney
