import React, { useEffect } from 'react'
import { TypeAnimation } from 'react-type-animation'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AOS from 'aos'
import GithubBoard from './contributions/GithubBoard'

const Home = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Container data-aos="fade-up" className="d-block font-monospace">
      <Row>
        <Col className="text-center my-auto">
          <p style={{ fontSize: '2em' }}>Hi! My name is Ardika Prasetyo,</p>
          <TypeAnimation
            sequence={[
              `I am a Software Engineer`,
              2000,
              `I am a Back-End Engineer`,
              2000,
              `I am a Full-stack Engineer`,
              2000
            ]}
            speed={50}
            repeat={Infinity}
            style={{ fontSize: '1.5em' }}
          />
        </Col>
        <Col className="col-2">
          <Image src="profpict.jpg" className="img-fluid mx-auto rounded" />
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <GithubBoard />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
