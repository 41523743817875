type ContributionDay = {
  contributionCount: number
  contributionLevel: ContributionLevelName
  date: string
  color: string
}

const CONTRIBUTION_LEVELS = {
  NONE: 0,
  FIRST_QUARTILE: 1,
  SECOND_QUARTILE: 2,
  THIRD_QUARTILE: 3,
  FOURTH_QUARTILE: 4
}
type ContributionLevelName = keyof typeof CONTRIBUTION_LEVELS

type ContributionOptions = {
  from?: string
  to?: string
}

type ContributionResponse = {
  data: {
    user: {
      contributionsCollection: {
        totalCommitContributions: number
        totalPullRequestContributions: number
        totalPullRequestReviewContributions: number
        totalRepositoriesWithContributedCommits: number
        contributionCalendar: {
          totalContributions: number
          weeks: {
            contributionDays: ContributionDay[]
          }[]
        }
      }
    }
  }
}

const fetchContributions = async (
  userName: string,
  token: string,
  contributionOptions: ContributionOptions = {}
) => {
  if (!userName || !token) {
    throw new Error('Missing required arguments')
  }
  const { from, to } = contributionOptions

  const query = `
query ($userName: String!, $from: DateTime, $to: DateTime) {
  user(login: $userName) {
    contributionsCollection(from: $from, to: $to) {
      totalCommitContributions
      totalPullRequestContributions
      totalPullRequestReviewContributions
      totalRepositoriesWithContributedCommits
      contributionCalendar {
        totalContributions
        weeks {
          contributionDays {
            color
            contributionCount
            contributionLevel
            date
          }
        }
      }
    }
  }
}
 `
  const variables = JSON.stringify({ userName, from, to })

  const json = { query, variables }
  const url = 'https://api.github.com/graphql'
  const { data } = await fetch(url, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(json)
  }).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response.json()
    } else {
      throw new Error(`Error Status Code : ${response.status}`)
    }
  }).catch(error => {
    console.error(error)
  }) as ContributionResponse

  const contributionCalendar = data?.user?.contributionsCollection?.contributionCalendar

  if (
    !contributionCalendar || !Object.hasOwn(contributionCalendar, 'weeks') ||
    !Object.hasOwn(contributionCalendar, 'totalContributions')
  ) {
    throw new Error('Could not get contributions data')
  }

  const { weeks, totalContributions }: {
    weeks: { contributionDays: ContributionDay[] }[]
    totalContributions: number
  } = contributionCalendar

  const contributions = weeks.map((week) => week.contributionDays)

  const {
    totalCommitContributions,
    totalPullRequestContributions,
    totalPullRequestReviewContributions,
    totalRepositoriesWithContributedCommits
  } = data?.user?.contributionsCollection

  return {
    contributions,
    totalContributions,
    totalCommitContributions,
    totalPullRequestContributions,
    totalPullRequestReviewContributions,
    totalRepositoriesWithContributedCommits
  }
}

const getContributions = async (
  userName: string,
  token: string,
  contributionOptions: ContributionOptions = {}
) => {
  const { from, to } = contributionOptions
  const {
    contributions,
    totalContributions,
    totalCommitContributions,
    totalPullRequestContributions,
    totalPullRequestReviewContributions,
    totalRepositoriesWithContributedCommits
  } = await fetchContributions(
    userName,
    token,
    { from, to }
  )

  return {
    contributions,
    totalContributions,
    totalCommitContributions,
    totalPullRequestContributions,
    totalPullRequestReviewContributions,
    totalRepositoriesWithContributedCommits
  }
}

export {
  CONTRIBUTION_LEVELS,
  getContributions
}

export type {
  ContributionDay,
  ContributionLevelName,
  ContributionOptions,
  ContributionResponse
}