import React from 'react'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'

export const Footer = () => {
  return (
    <footer
      id="footer"
      className="p-4 d-flex align-items-center bg-dark text-light"
    >
      <Container className="text-center">
        <div>
          Made by <FontAwesomeIcon icon={faHeart} />
        </div>
        <div>
          Built using <code>['ReactJS', 'Typescript', 'Bootstrap']</code>
        </div>
      </Container>
    </footer>
  )
}
