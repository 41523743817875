import { Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import styles from '../styles.module.css'
import { Link } from 'react-router-dom'
import TechJourney from '../techjourney'
import jobExperiences from './type'
import FilterButton from './FilterButton'

const Resume = () => {
  const [selectedHashtags, setSelectedHashtags] = useState<string[]>([])

  useEffect(() => {
    AOS.init()
  }, [])

  const filteredJobExperiences = selectedHashtags.length > 0
    ? jobExperiences.filter((experience) =>
      selectedHashtags.every((hashtag) =>
        experience.hashtags.includes(hashtag)
      )
    )
    : jobExperiences

  const toggleHashtag = (hashtag: string) => {
    if (selectedHashtags.includes(hashtag)) {
      // Remove the hashtag if it's already selected
      setSelectedHashtags(selectedHashtags.filter((h) => h !== hashtag))
    } else {
      // Add the hashtag if it's not selected
      setSelectedHashtags([...selectedHashtags, hashtag])
    }
  }

  const isHashtagSelected = (hashtag: string) => {
    return selectedHashtags.includes(hashtag)
  }

  const clearSelection = () => {
    // Clear all selected hashtags
    setSelectedHashtags([])
  }
  const availableHashtags: string[] = ['java', 'react', 'typescript', 'golang', 'microservices',
    'loan-origination-system', 'redis', 'message-queue', 'postgresql', 'tech-lead',
    'spring-boot', 'coldfusion', 'sql-server', 'oracle-db', 'banking', 'oracle-weblogic', 'selenium', 'vaadin']

  return (
    <Container data-aos="fade-up" className="d-block">
      <div className="section-short-summary text-center align-middle">
        <h2 className="text-uppercase fw-bold pb-3">Resume</h2>
        <div className={`${styles['border-bottom']}`} />
        <p className="pt-4">
          Experienced Software Engineer, with a diverse background in software
          engineering and a proven track record of interact with client, such as
          requirement gathering, analyzing impact to system, project planning,
          until production support.
        </p>
      </div>
      <Row>
        <Col className="col-6">
          <h3 className={`${styles['resume-title']}`}>Summary</h3>
          <div className={`${styles['resume-item']} pb-0`}>
            <Row>
              <Col className="col-3">
                <h4>Current Role</h4>
              </Col>
              <Col className="col-9">
                <p>Senior Software Engineer</p>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <h4>Skills</h4>
                <div className="mb-4">
                  {availableHashtags.map((hashtag) => (
                    <FilterButton
                      key={hashtag}
                      hashtag={hashtag}
                      isSelected={isHashtagSelected(hashtag)}
                      onClick={() => toggleHashtag(hashtag)}
                    />
                  ))}
                  <button
                    className="mt-1 btn btn-outline-secondary"
                    onClick={clearSelection}
                  >
                    Clear Selection
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-3">
                <h4>Domains</h4>
              </Col>
              <Col className="col-9">
                <ul>
                  <li>Banking</li>
                  <li>Fin-Tech</li>
                  <li>Research & Development</li>
                  <li>Loan Origination System</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col className="col-3">
                <h4>Soft Skills</h4>
              </Col>
              <Col className="col-9">
                <ul>
                  <li>Good in adaptability</li>
                  <li>Fast learner</li>
                  <li>Team player</li>
                  <li>Experienced on Project Planning</li>
                  <li>Self management</li>
                  <li>Able to work on under pressure</li>
                </ul>
              </Col>
            </Row>
          </div>

          <h3 className={`${styles['resume-title']}`}>Education</h3>
          <div className={`${styles['resume-item']} pb-0`}>
            <h4>Bachelor of Computer Science</h4>
            <h5>2006 - 2010</h5>
            <p>
              <em>Indonesia University of Education, Bandung, Indonesia</em>
            </p>
            <Link to="http://repository.upi.edu/2942/" className="text-dark">
              Implementasi teknik gamifikasi pada sistem e-learning
            </Link>
          </div>
        </Col>
        <Col className="col-6">
          <TechJourney jobExperiences={filteredJobExperiences} />
        </Col>
      </Row>
    </Container>
  )
}

export default Resume
