export type JobExperience = {
  id: number
  title: string
  date: string
  company: string
  description: string
  highlights: string[]
  hashtags: string[]
};

const jobExperiences: JobExperience[] = [
  {
    id: 0,
    title: 'Senior Software Engineer',
    date: 'Jun 2022 - present',
    company: 'Paymentology, Remote',
    description:
      'Paymentology is a leading financial technology company that focuses on providing cutting-edge solutions for payment processing and card management.',
    highlights: [
      'Designed and implemented Tokenization Lifecycle Management.',
      'Designed and implemented Single Sign-On with Azure AD.',
      'Designed and implemented a service that calculates metrics from AWS Athena Log.',
      'Handling 2 big system, with different client and different tech stack'
    ],
    hashtags: ['java', 'spring-boot', 'coldfusion', 'postgresql', 'sql-server', 'react', 'typescript']
  },
  {
    id: 1,
    title: 'Senior Software Engineer',
    date: 'Jan 2021 - Jun 2022',
    company: 'Tokopedia, Indonesia',
    description:
      'Dhanapala, a subsidiary of Tokopedia, is a dynamic team dedicated to advancing financial solutions and services for merchants on the Tokopedia platform.',
    highlights: [
      'Rebuild architecture from monolith become microservice.',
      'Enabling the loan matching process for multi-lenders.'
    ],
    hashtags: ['golang', 'microservices', 'loan-origination-system', 'redis', 'message-queue', 'postgresql', 'tech-lead']
  },
  {
    id: 2,
    title: 'Technical Lead',
    date: 'Aug 2018 - Jan 2021',
    company: 'Hitachi eBworx Sdn Bhd, Malaysia',
    description:
      'Hitachi Ebworx is a global software solutions and services provider specializing in financial technology. They deliver a wide range of software solutions for banks and financial institutions.',
    highlights: [
      'Led performance testing on RHB Bank project.',
      'Led a technical team on the RHB Bank project to implement Auto Financing.'
    ],
    hashtags: ['java', 'oracle-db', 'banking', 'oracle-weblogic', 'tech-lead']
  },
  {
    id: 3,
    title: 'Senior Software Engineer',
    date: 'Aug 2017 - Aug 2018',
    company: 'Hitachi Energy (former PT ABB Sakti Industri), Indonesia',
    description:
      'As part of the Research and Development team, I contributed to the development and maintenance of an internal Java framework for efficient software development.',
    highlights: [
      'Participate in migrating from a Flex-based framework to a modern Java-based framework.',
      'Migrating from a Flex-based automation testing to Java-based automation testing.'
    ],
    hashtags: ['java', 'selenium', 'postgresql', 'vaadin']
  },
  {
    id: 4,
    title: 'Senior Application Engineer',
    date: 'Dec 2014 - Aug 2017',
    company: 'Hitachi eBworx Sdn Bhd, Malaysia',
    description:
      'Hitachi Ebworx is a global software solutions and services provider specializing in financial technology. They deliver a wide range of software solutions for banks and financial institutions.',
    highlights: [
      'Led a small team to perform Tech Refresh.',
      'Led a technical team on the AmBank project to implement Non-Mortgage.',
      'Do production support for the existing system.'
    ],
    hashtags: ['java', 'oracle-db', 'banking', 'oracle-weblogic']
  }
]

export default jobExperiences
