import { Card, CardBody, CardHeader, Col, Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import Calendar from '../calendar'
import { getContributions } from '../github_stats/contributions'

type SummaryData = {
  totalContributions: number,
  totalCommitContributions: number,
  totalPullRequestContributions: number,
  totalPullRequestReviewContributions: number,
  totalRepositoriesWithContributedCommits: number
}

const until = new Date().toDateString()
const panelColors = [
  '#EEE',
  '#D6E685',
  '#8CC665',
  '#44A340',
  '#1E6823'
]

const labelAttributes = {
  'style': {
    'fontSize': 10,
    'fill': '#000'
  }
}

const GithubBoard = () => {
  const [githubContributions, setGithubContributions] = useState({})
  const [contributionSummary, setContributionSummary] = useState<SummaryData>()

  const githubToken: string = (process.env.REACT_APP_GITHUB_TOKEN as string)

  useEffect(() => {
    getContributions('rr-ardika-prasetyo', githubToken)
      .then(value => {
        const contributionsData: { [date: string]: number } = {}

        for (const week of value.contributions) {
          for (const day of week) {
            contributionsData[day.date] = day.contributionCount
          }
        }

        setGithubContributions(contributionsData)
        setContributionSummary({
          totalContributions: value.totalContributions ?? 0,
          totalCommitContributions: value.totalCommitContributions,
          totalPullRequestContributions: value.totalPullRequestContributions,
          totalPullRequestReviewContributions: value.totalPullRequestReviewContributions,
          totalRepositoriesWithContributedCommits: value.totalRepositoriesWithContributedCommits
        })
      })
      .catch(reason => {
        console.error('Error fetching contributions:', reason)
      })
  }, [githubToken])
  return (
    <Card className="mt-2 text-bg-light">
      <CardHeader className="bg-dark-subtle">
        Github Contributions
      </CardHeader>
      <CardBody className="bg-secondary-subtle">
        <Container>
          <Row>
            <Col className="col-7">
              <Calendar values={githubContributions} until={until} panelColors={panelColors}
                        monthLabelAttributes={labelAttributes} weekLabelAttributes={labelAttributes} />
            </Col>
            <Col className="col-5">
              <h6>Past Year Highlights:</h6>
              <p>
                <small>
                  Total contributions: {`${contributionSummary?.totalContributions}`}<br />
                  Total commits: {`${contributionSummary?.totalCommitContributions}`}<br />
                  Total pull requests: {`${contributionSummary?.totalPullRequestContributions}`}<br />
                  Total pull request reviews: {`${contributionSummary?.totalPullRequestReviewContributions}`}<br />
                  Total repositories with contributed
                  commits: {`${contributionSummary?.totalRepositoriesWithContributedCommits}`}<br />
                </small>
              </p>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  )
}

export default GithubBoard
